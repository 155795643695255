import { useMemo } from 'react';
import { useAppContext } from '../contexts/appContext';

export const useUserAllowedNamespaces = ({
    selectedNamespace,
}: { selectedNamespace?: string } = {}) => {
    const { user } = useAppContext();

    const { groups = [] } = user ?? {};

    const prefix = 'spaceport-';
    const suffix = '-access';

    return useMemo(() => {
        const filteredGroups =
            groups
                ?.filter(group => group.startsWith(prefix) && group.endsWith(suffix))
                ?.map(group => group.replace(prefix, '').replace(suffix, '')) ?? [];

        return filteredGroups.length > 0 ? filteredGroups : [selectedNamespace];
    }, [groups, selectedNamespace]);
};
