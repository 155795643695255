// import resolveConfig from 'tailwindcss/resolveConfig';
// import tailwindConfig from 'tailwind.config.js';
import { useMediaQuery } from 'react-responsive';

// const { screens = {}, content } = tailwindConfig;
// const { theme } = resolveConfig({
//     content,
// });

// Getting the config above is ... a lot more difficult than expected
// So we'll do it manual and hope no one uses a custom media config
// sm	640px
// md	768px
// lg	1024px
// xl	1280px
// 2xl 1536px
export function useBreakpoint() {
    const sm = useMediaQuery({ query: '(min-width: 640px)' });
    const md = useMediaQuery({ query: '(min-width: 768px)' });
    const mdDown = useMediaQuery({ query: '(max-width: 768px)' });
    const lg = useMediaQuery({ query: '(min-width: 1024px)' });
    const xl = useMediaQuery({ query: '(min-width: 1280px)' });
    const xxl = useMediaQuery({ query: '(min-width: 1536px)' });
    return {
        sm,
        md,
        lg,
        xl,
        xxl,
        down: {
            md: mdDown,
        },
    };
}
