import { useRouter } from 'next/router';
import { useCallback } from 'react';

type HandleURLParamsChangePropsType<Params extends string> = {
    value?: string | boolean | string[];
    key?: Params;
    extraCleaning?: Params[];
    multipleValues?: Partial<Record<Params, string | boolean | string[]>>;
    action?: 'replace' | 'push';
};
function useRouterQuery<Params extends string>() {
    const router = useRouter();
    const handleURLParamsChange = useCallback(
        ({
            value,
            key,
            extraCleaning = [],
            multipleValues,
            action = 'replace',
        }: HandleURLParamsChangePropsType<Params>) => {
            if (!router.isReady) return;
            const queries = router.query ?? {};
            let newQuery = {};
            if (Object.keys(queries).length > 0) {
                newQuery = Object.entries(queries).reduce((acc, [qKey, qVal]) => {
                    if (extraCleaning.includes(qKey as Params) && !multipleValues?.[qKey])
                        return acc;
                    if (key === qKey && !value && !multipleValues?.[qKey]) return acc;
                    if (key === qKey || multipleValues?.[qKey]) {
                        return { ...acc, [qKey]: multipleValues?.[qKey] || value };
                    }
                    return {
                        ...acc,
                        [qKey]: qVal,
                    };
                }, {});
            }

            const query = Object.entries({
                ...newQuery,
                ...((value && { [key]: value }) || {}),
                ...multipleValues,
            }).reduce((acc, [k, v]) => (!v ? acc : { ...acc, [k]: v }), {});

            router[action]({
                pathname: router.pathname,
                query,
            });
        },
        [router],
    );
    return { handleURLParamsChange, router };
}

export default useRouterQuery;
