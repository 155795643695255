import cx from 'classnames';
import type { ReactNode } from 'react';
import { Divider } from '@mantine/core';
import { StickyHeader } from './StickyHeader';

interface SpaceportCommonHeaderProps {
    title: string;
    description?: ReactNode;
    actionsElement?: ReactNode;
    className?: string;
    containerClassName?: string;
    children?: ReactNode;
    titleClassName?: string;
    breadcrumbsCom?: JSX.Element;
}

export const SpaceportCommonHeader = ({
    title,
    description,
    actionsElement,
    className,
    containerClassName,
    children,
    titleClassName,
    breadcrumbsCom,
}: SpaceportCommonHeaderProps) => (
    <StickyHeader className={cx('pt-6 pb-5 px-4 md:px-10 lg:px-[72px]', className)}>
        {breadcrumbsCom && <div className="mb-3">{breadcrumbsCom}</div>}
        <div
            className={cx(
                'flex flex-col gap-4 items-start justify-between mb-5',
                'md:flex-row xl:items-center',
                containerClassName,
            )}
        >
            <div className="flex flex-col gap-1">
                <h1 className={cx('font-semibold text-primary text-lg md:text-xl', titleClassName)}>
                    {title}
                </h1>
                {description && (
                    <div className="whitespace-pre-wrap text-xs text-gray-500">{description}</div>
                )}
            </div>
            {actionsElement && actionsElement}
        </div>
        {children}
        <Divider />
    </StickyHeader>
);
