import { useElementSize, useWindowScroll } from '@mantine/hooks';
import cx from 'classnames';
import React from 'react';
import { CommonProps } from '../responsive/types';

export interface StickyHeaderProps extends CommonProps {
    containerClassName?: string;
}

export const StickyHeader = ({ className, containerClassName, children }: StickyHeaderProps) => {
    const { ref, height } = useElementSize<HTMLDivElement>();
    const [{ y }] = useWindowScroll();

    return (
        <div style={{ height: height || 120 }}>
            <div
                ref={ref}
                style={{ background: 'var(--body-bg)' }}
                className={cx('fixed top-0 right-0 left-0 md:left-20 z-40', containerClassName)}
            >
                <div
                    className={cx(
                        'transition-all duration-300 ease-in-out',
                        {
                            'shadow-xl': y > 10,
                        },
                        className,
                    )}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
