import { useMemo } from 'react';
import { groupBy } from 'lodash';
import useApiQuery from '../../hooks/useApiQuery';
import { ConsoleApiPaths } from './experiments/ApiPaths';
import { ConsoleData } from './types';

export const useStasusesData = (env: string) => {
    const { data, ...rest } = useApiQuery<ConsoleData[]>({ url: ConsoleApiPaths.Statuses });
    const dataByEnv = useMemo(() => groupBy(data, 'environment'), [data]);

    return {
        data: (env ? dataByEnv[env] : data) ?? [],
        ...rest,
    };
};

export const useActiveUseCasesData = (namespace: string) => {
    const { data, ...rest } = useApiQuery<{ use_cases: string[] }>({
        url: ConsoleApiPaths.activeUseCases(namespace),
        disabled: !namespace,
    });

    return {
        data: data?.use_cases ?? [],
        ...rest,
    };
};
