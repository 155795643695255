import { BOREALIES_API_URL, SPACEPORT_API_URL } from '../../../buildConfig/processEnv';
import { SubscribeAlertNameType } from '../types';

export const ConsoleApiPaths = {
    Statuses: `${SPACEPORT_API_URL}/spaceport/api/console/statuses`,
    activeUseCases: (namespace: string) =>
        `${BOREALIES_API_URL}/spaceport/api/console/${namespace}/active_use_cases`,
    // ? [[[additionalProp1]]] {"country_code": namespace}
    subscribe: `${SPACEPORT_API_URL}/spaceport/api/alerts/subscribe`,
    metricNames: `${SPACEPORT_API_URL}/spaceport/api/metrics/metric_names`,
    unsubscribe: `${SPACEPORT_API_URL}/spaceport/api/alerts/unsubscribe`,
    subscriptionsList: ({
        alert_name,
        namespace,
    }: {
        alert_name: SubscribeAlertNameType;
        namespace?: string;
    }) =>
        `${SPACEPORT_API_URL}/spaceport/api/alerts/list_alert_subscriptions?alert_name=${alert_name}${namespace ? `&namespace=${namespace}` : ''}`,
    subscriptionsListRequests: ({
        alert_name,
        namespace,
    }: {
        alert_name: SubscribeAlertNameType;
        namespace?: string;
    }) =>
        `${SPACEPORT_API_URL}/spaceport/api/alerts/list_subscribers_for_alert?alert_name=${alert_name}${namespace ? `&namespace=${namespace}` : ''}`,
};
