import moment from 'moment-timezone';

export class DateFormatter {
    static formatDate(ts?: string | moment.Moment | number | Date): string {
        const time = moment.utc(ts).local();
        const tzAbbr = moment.tz(time, moment.tz.guess()).zoneAbbr();
        return `${time.format(`ll [at] LT [(${tzAbbr})]`)}`;
    }

    static formatDateAsText(ts: string | moment.Moment | number | Date): string {
        return moment(ts).format('ll');
    }

    static formatDateAsMonthandYear(ts: string | moment.Moment | number | Date): string {
        return moment(ts).format('MMM YYYY');
    }

    static formatDateAsMonthandDay(ts: string | moment.Moment | number | Date): string {
        return moment(ts).format('MMMM DD');
    }

    static formatDateWeekly(ts: string | moment.Moment | number | Date): string {
        return moment(ts).format('MMM DD YYYY');
    }
}
