import cx from 'classnames';
import {
    __InputStylesNames,
    Combobox,
    ComboboxProps,
    MantineStyleProp,
    Skeleton,
    useCombobox,
} from '@mantine/core';
import React, { useMemo, useState } from 'react';
import { X } from 'phosphor-react';
import Dropdown from './dropdown';
import SelectLabelWrapper from './selectLabelWrapper';
import { SelectsDataType } from '../types/select/common';
import { CustomTextType } from '../text';
import Caret from '../caret';
import { getKeys } from '../util/selects';

type CXS = {
    select?: ComboboxProps['classNames'];
    input?: Partial<Record<__InputStylesNames, string>>;
};
const Select = React.forwardRef(
    (
        {
            data,
            onChange,
            value,
            classNames,
            loading,
            preventSkeleton,
            width = 200,
            checkIcon,
            optionLeftIcon,
            placeholder,
            label,
            className,
            disabled,
            id,
            textType,
            clearable,
            formattingFunction,
            // ? DropDownComponent: Switching the default label to be side by side with
            // ? the select and make the icon visible when the component has focus
            cleanDesign,
            leftSection,
            onSearchChange,
            searchVal,
            withinPortal,
            gradientBG,
            getCreateLabel,
            onCreate,
            creatable,
            defaultValue,
            limit,
            searchable = true,
            error,
            rightSection,
            clearButtonOnClick,
            dropdownClassName,
            dropdownStyle,
            nothingFoundMessage,
        }: {
            data: SelectsDataType[];
            onChange: (str?: string) => void;
            formattingFunction?: (str: string) => string;
            value?: string;
            placeholder?: string;
            id?: string;
            className?: string;
            label?: string | JSX.Element;
            textType?: CustomTextType;
            classNames?: CXS;
            loading?: boolean;
            preventSkeleton?: boolean;
            disabled?: boolean;
            width?: number | string;
            checkIcon?: boolean;
            optionLeftIcon?: JSX.Element;
            leftSection?: JSX.Element;
            rightSection?: JSX.Element;
            clearable?: boolean;
            cleanDesign?: boolean;
            withinPortal?: boolean;
            searchVal?: string;
            onSearchChange?: (str?: string) => void;
            gradientBG?: boolean;
            creatable?: boolean;
            getCreateLabel?: (query: string) => string;
            onCreate?: (query: string) => string;
            defaultValue?: string;
            limit?: number;
            searchable?: boolean;
            error?: boolean | string;
            clearButtonOnClick?: () => void;
            dropdownClassName?: string;
            dropdownStyle?: MantineStyleProp;
            nothingFoundMessage?: string;
        },
        ref: React.Ref<HTMLInputElement>,
    ) => {
        const [searchValue, setSearchValue] = useState<string | undefined>(searchVal);
        const [isMouseInside, setIsMouseInside] = useState(false);
        const isClearable = value && defaultValue !== value && clearable;
        const combobox = useCombobox({
            onDropdownClose: () => {
                setSearchValue(undefined);
                combobox.resetSelectedOption();
            },
        });

        const keysValue = useMemo(
            () => (typeof data?.[0] === 'string' ? {} : getKeys(data)),
            [data],
        );

        if (loading && !preventSkeleton) {
            return <Skeleton height={40} width={width} />;
        }
        const foundValue = (value && (keysValue?.[value]?.label ?? value)) || undefined;
        return (
            <SelectLabelWrapper
                label={label}
                textType={textType}
                cleanDesign={cleanDesign}
                disabled={disabled}
            >
                <Combobox
                    store={combobox}
                    onOptionSubmit={val => {
                        onChange(val);
                        setSearchValue(undefined);
                        onSearchChange?.(undefined);
                        combobox.closeDropdown();
                    }}
                    classNames={classNames?.select}
                    width={width}
                    withinPortal={withinPortal}
                    position="bottom-start"
                    transitionProps={{ duration: 300, transition: 'pop' }}
                >
                    <Combobox.Target>
                        <div className="relative flex w-fit">
                            <Combobox.Search
                                id={id}
                                className={cx('capitalize', className)}
                                ref={ref}
                                classNames={{
                                    ...classNames?.input,
                                    input: cx(
                                        classNames?.input?.input,
                                        '!cursor-pointer disabled:!cursor-default z-10 h-10 !min-h-[40px] !border-none !bg-secondary-5 w-full placeholder:text-sm w-full whitespace-nowrap !overflow-hidden text-ellipsis disabled:!text-navy-solid-30 disabled:hover:!text-navy-solid-15 !text-navy hover:!text-navy-solid-70 disabled:!opacity-100',
                                        {
                                            '!border-white': combobox.dropdownOpened,
                                            '!border-none !bg-transparent': cleanDesign,
                                            '!pl-0': label && cleanDesign,
                                        },
                                    ),
                                    wrapper: cx(
                                        classNames?.input?.wrapper,
                                        'cursor-pointer z-10 !border-border-color data-[error=true]:!border-red-700 !bg-secondary-5 !w-min !border !rounded placeholder:text-sm w-full whitespace-nowrap !overflow-hidden text-ellipsis disabled:!text-navy-solid-15 !text-navy-solid-50',
                                        {
                                            '!border-none !bg-transparent': cleanDesign,
                                            'gradient-bg-hex': gradientBG,
                                        },
                                    ),
                                    section: cx({
                                        '!text-navy': keysValue?.[value]?.icon && !leftSection,
                                    }),
                                }}
                                value={
                                    typeof searchValue === 'string'
                                        ? searchValue
                                        : (foundValue && formattingFunction?.(foundValue)) ??
                                          foundValue
                                }
                                styles={{
                                    input: {
                                        maxWidth: width,
                                        width,
                                    },
                                }}
                                rightSection={
                                    rightSection || (
                                        <Caret
                                            className={cx({
                                                'caret-icon': cleanDesign,
                                                '!text-navy-solid-15': disabled,
                                                '!text-navy-solid-50': !disabled,
                                                hidden:
                                                    isClearable || (!isMouseInside && isClearable),
                                            })}
                                            open={combobox.dropdownOpened}
                                        />
                                    )
                                }
                                leftSection={leftSection ?? keysValue?.[value]?.icon}
                                onChange={event => {
                                    if (!searchable) return;
                                    setSearchValue(event.currentTarget.value);
                                    onSearchChange?.(event.currentTarget.value);
                                    combobox.openDropdown();
                                }}
                                style={{ width }}
                                onClick={() => combobox.toggleDropdown()}
                                disabled={disabled}
                                placeholder={placeholder}
                                type="text"
                                readOnly={!searchable}
                                error={error}
                                onMouseEnter={() => setIsMouseInside(true)}
                                onMouseLeave={() => setIsMouseInside(false)}
                            />
                            {clearable && isClearable && (
                                <button
                                    type="button"
                                    aria-label="clear select"
                                    className="absolute right-0 z-[500] flex h-full w-[35px] items-center justify-center"
                                    onClick={() => {
                                        setSearchValue(undefined);
                                        onChange(defaultValue);
                                        clearButtonOnClick?.();
                                    }}
                                    disabled={false}
                                >
                                    <X size={16} />
                                </button>
                            )}
                        </div>
                    </Combobox.Target>
                    <Dropdown
                        width={width}
                        data={data}
                        value={value ?? ''}
                        checkIcon={checkIcon}
                        rightIcon={optionLeftIcon}
                        searchValue={searchValue}
                        formattingFunction={formattingFunction}
                        gradientBG={gradientBG}
                        creatable={creatable}
                        getCreateLabel={getCreateLabel}
                        onCreate={onCreate}
                        limit={limit}
                        dropdownClassName={dropdownClassName}
                        dropdownStyle={dropdownStyle}
                        nothingFoundMessage={nothingFoundMessage}
                    />
                </Combobox>
            </SelectLabelWrapper>
        );
    },
);

export default Select;
