import classNames from 'classnames';
import { motion } from 'framer-motion';
import { CaretDown } from 'phosphor-react';

function Caret({
    open,
    className,
    size = 16,
    color,
}: {
    open: boolean;
    className?: string;
    size?: number;
    color?: string;
}) {
    return (
        <motion.div
            animate={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            transition={{
                easings: ['easeIn', 'easeOut'],
                duration: 0.3,
                delay: 0,
            }}
            className={classNames('inline-block', className)}
        >
            <CaretDown size={size} color={color} />
        </motion.div>
    );
}

export default Caret;
