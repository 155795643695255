import { FC, useState } from 'react';
import Image from 'next/image';
import { Collapse, Text } from '@mantine/core';
import { motion } from 'framer-motion';
import Caret from '../caret';

const SuggestionBox = ({ suggestion }: { suggestion: string }) => (
    <motion.div
        whileHover={{ scale: 1.01 }}
        transition={{ duration: 0.2 }}
        whileTap={{ scale: 0.95 }}
        className="flex h-full max-w-[162px] flex-col p-4"
        style={{
            borderRadius: '4px',
            background: `linear-gradient(135deg, rgba(230, 236, 242, 0.30) 0%, rgba(240, 234, 243, 0.30) 100%)`,
            boxShadow: `4px 4px 8px 0px rgba(10, 12, 15, 0.05)`,
            backdropFilter: `blur(8px)`,
            borderTop: `0.5px solid rgba(0, 0, 0, 0.10)`,
            borderLeft: `0.5px solid rgba(0, 0, 0, 0.10)`,
        }}
    >
        <div className="flex flex-row items-start pb-2 text-start">
            <Image src="/images/idea.svg" alt="bulb" width={16} height={16} />
            <Text
                style={{
                    color: `var(--navy-solid-30, #B1B5C1)`,
                    fontSize: '11px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    letterSpacing: '0.3px',
                    textTransform: 'uppercase',
                    padding: '2px',
                }}
            >
                SUGGESTION
            </Text>
        </div>
        <Text
            style={{
                color: `var(--navy-solid-50, #81859B)`,
                fontSize: '11px',
                fontWeight: 500,
                lineHeight: 'normal',
            }}
            className="text-start"
        >
            {suggestion}
        </Text>
    </motion.div>
);

interface ChatSuggestionsProps {
    suggestions?: string[];
    onSelect: (suggestion: string) => void;
    suggestionCaretColor?: string;
}

const ChatSuggestions: FC<ChatSuggestionsProps> = ({
    suggestions = [],
    onSelect,
    suggestionCaretColor,
}) => {
    const [showSuggestions, setShowSuggestions] = useState(true);

    return (
        <div className="flex flex-col">
            <button
                type="button"
                className="flex cursor-pointer items-center gap-1"
                onClick={() => setShowSuggestions(!showSuggestions)}
                style={{
                    color: `var(--navy-solid-70, #515674)`,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: 'normal',
                }}
            >
                <Text
                    style={{
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: 'normal',
                    }}
                >
                    Suggestions
                </Text>
                <Caret
                    open={showSuggestions}
                    color={suggestionCaretColor}
                    size={13}
                    className="mt-0.5"
                />
            </button>
            <Collapse in={showSuggestions}>
                <div className="flex flex-row gap-4 pt-4">
                    {suggestions.map((suggestion, index) => (
                        <button
                            type="button"
                            onClick={() => {
                                onSelect(suggestion);
                                setShowSuggestions(false);
                            }}
                            key={`suggestion_n_${index * 1}`}
                            className="cursor-pointer"
                            aria-label={`suggestion n${index}`}
                        >
                            <SuggestionBox suggestion={suggestion} />
                        </button>
                    ))}
                </div>
            </Collapse>
        </div>
    );
};

export default ChatSuggestions;
