import { Image } from '@mantine/core';
import React from 'react';

interface OrbyLoaderProps {
    className?: string;
    size?: number;
    variant?: 'colored' | 'gray';
}

export const OrbyLoader: React.FC<OrbyLoaderProps> = ({
    className,
    size = 24,
    variant = 'colored',
}) => (
    <Image
        src={variant === 'colored' ? '/images/orbyLoader.gif' : '/videos/gray-orby.gif'}
        width={size}
        height={size}
        alt="orby-loader"
        className={className}
        styles={{
            root: {
                display: 'inline',
                width: `${size}px`,
            },
        }}
    />
);
