import React from 'react';
import { Text } from '@mantine/core';
import { CheckCircle, Sparkle } from 'phosphor-react';
import classNames from 'classnames';
import { Mode } from './types';
import { PIPELINE_PROGRESS_REPORT } from './constants';
import { OrbyLoader } from '../OrbyLoader';

interface ChatInfoProps {
    mode: Mode;
    hidden?: boolean;
}

const ChatInfo: React.FC<ChatInfoProps> = ({ mode, hidden = false }) => {
    const starterText = (
        <div
            className={classNames('flex flex-row', {
                hidden,
            })}
        >
            <Text
                className="py-2"
                style={{
                    color: 'var(--navy-solid-50, #81859B)',
                    fontFamily: 'Manrope',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                }}
            >
                Welcome to Hermes, a source of truth for consumer packaged goods. Before connecting
                your data, you can generate insights to better understand the market. To begin, ask
                a question or start with one of the suggestions below.
            </Text>
        </div>
    );

    const statusText = (
        <div className="flex flex-col gap-6 py-6">
            <div className="flex flex-row">
                <OrbyLoader variant="gray" size={32} className="!w-12" />
                <Text
                    className="pl-4"
                    style={{
                        color: 'var(--navy-solid-50, #81859B)',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: 'normal',
                    }}
                >
                    With the recent completion of data ingestion, here’s a list of insights that can
                    be inferred from your data source:
                </Text>
            </div>

            <div className="flex flex-col gap-2 pl-10">
                {PIPELINE_PROGRESS_REPORT.map(item => (
                    <div className="flex flex-row gap-2" key={item.text}>
                        {item.status === 'done' ? (
                            <CheckCircle size={20} className="mt-2" color="#4C9355" />
                        ) : (
                            <Sparkle size={20} className="mt-2" color="#B1B5C1" />
                        )}
                        <Text
                            className="pt-2"
                            style={{
                                color:
                                    item.status === 'done'
                                        ? `var(--navy-solid-50, #81859B)`
                                        : `var(--navy-solid-30, #B1B5C1)`,
                                fontFamily: 'Manrope',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                            }}
                        >
                            {item.text}
                        </Text>
                    </div>
                ))}
            </div>
            <Text
                className="pl-10 pt-4"
                style={{
                    color: 'var(--navy-solid-50, #81859B)',
                    fontFamily: 'Manrope',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                }}
            >
                View suggestions below or query Hermes based on these insight types.
            </Text>
        </div>
    );

    return (
        <>
            <div className="h-24 pt-8">
                <Text
                    style={{
                        color: `var(--navy-solid-30, #B1B5C1)`,
                        fontSize: '29px',
                        fontWeight: 500,
                        lineHeight: 'normal',
                    }}
                >
                    Ask Hermes
                </Text>
            </div>
            {['initialize', 'full-page'].includes(mode) ? starterText : statusText}
        </>
    );
};

export default ChatInfo;
