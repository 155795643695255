import { showNotification } from '@mantine/notifications';

const notification = ({
    loading,
    message,
    icon,
    color,
}: {
    loading?: boolean;
    message: string;
    icon: JSX.Element;
    color?: string;
}) =>
    showNotification({
        message,
        color: loading ? 'blue' : color ?? 'unset',
        loading,
        icon,
        styles: {
            icon: {
                backgroundColor: `${'var(--secondary-bg)'} !important`,
                color: `${color ?? 'var(--secondary-color)'} !important`,
            },
            root: { backgroundColor: `${'var(--secondary-bg)'} !important` },
            description: {
                color: `${color ?? 'var(--secondary-color)'} !important`,
            },
        },
    });

export { notification };
