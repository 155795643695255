import colors from '@/constants/colors';

export type ConsoleModule =
    | 'QUICK_ORDER'
    | 'CROSS_SELL_UP_SELL'
    | 'FORGOTTEN_ITEMS'
    | 'DEAL_SORT'
    | 'FEATURED_OFFERS';

export const CONSOLE_MODULES: ConsoleModule[] = ['QUICK_ORDER', 'CROSS_SELL_UP_SELL'];
export const CONSOLE_MODULE_NAME_MAP: Record<ConsoleModule, string> = {
    QUICK_ORDER: 'Suggested Order',
    CROSS_SELL_UP_SELL: 'Upsell',
    FORGOTTEN_ITEMS: 'Forgotten Items',
    FEATURED_OFFERS: 'Featured Offers',
    DEAL_SORT: 'Deal Sort',
};
export const CONSOLE_MODULE_COLOR_MAP: Record<ConsoleModule, string> = {
    QUICK_ORDER: colors.purple,
    CROSS_SELL_UP_SELL: colors.red,
    FORGOTTEN_ITEMS: colors.darkGreen,
    FEATURED_OFFERS: colors.orange,
    DEAL_SORT: colors.magenta,
};

export const CONSOLE_TO_RESULT_MODULES: Record<string, string> = {
    replenishment: 'QUICK_ORDER',
    CROSS_SELL_UP_SELL: 'UPSELL',
    substitution: 'OUT_OF_STOCK_REPLACEMENT',
};

export const MODULES_ORDER: Record<string, number> = {
    QUICK_ORDER: 0,
    CROSS_SELL_UP_SELL: 1,
    FORGOTTEN_ITEMS: 2,
    OUT_OF_ORDER: 3,
    DEAL_SORT: 4,
};
