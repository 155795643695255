import { useGlobalContext } from '@/contexts/GlobalContext';
import Hamburger from 'hamburger-react';
import cx from 'classnames';

export default function MobileNavButton() {
    const { isMobileNavOpen, toggleMobileNav } = useGlobalContext(state => state);

    return (
        <button
            type="button"
            className={cx('appearance-none fixed right-1 top-0.5 z-[9999999] hidden max-md:block')}
            onClick={() => toggleMobileNav()}
            aria-label="Nav-btn"
        >
            <Hamburger size={20} toggled={isMobileNavOpen} />
        </button>
    );
}
