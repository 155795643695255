import { useAppContext } from 'contexts/appContext';
import { ArrowsCounterClockwise } from 'phosphor-react';

export default function UserOverrideDefaultBanner() {
    const { rawUser, user, handleUser } = useAppContext();
    return user.displayDeveloperTools === 'show' &&
        (!rawUser?.groups?.some(g => user?.groups?.includes(g)) ||
            rawUser?.groups?.length !== user?.groups?.length) ? (
        <div className="fixed bottom-0 z-[9999999999999] flex h-8 w-full items-center justify-center bg-risd-blue text-white">
            Your are overriding default user group to use default user group{' '}
            <button
                type="button"
                onClick={() => handleUser({ groups: rawUser.groups })}
                className="ml-2"
                aria-label="reset user group"
            >
                <ArrowsCounterClockwise />
            </button>
        </div>
    ) : null;
}
