export const DEMO_MESSAGES = [
    'There are 6,306 matching stores across both account masters. At the final pipeline step, ER will ensure that accounts are not created for stores that have already been onboarded.',
    '1. Trix Cereal 18x330g, 2. Nestle Trix Fruity Shaped Breakfast Cereal 330g, 3. Trix Breakfast Cereal 18x330g, 4. Trix BR Cereal 330g',
    'The neighborhoods with least sales are: 1. 11385, 2. 11233, 3. 12550',
    'Top neighborhoods are: 1. 10010, 2. 10028, 3. 12023',
];

export const PIPELINE_PROGRESS_REPORT = [
    { text: 'Purchase likelihood', status: 'done' },
    { text: 'Price sensitivity', status: 'done' },
    { text: 'Purchase volume', status: 'done' },
    { text: 'Seasonal/holiday impact', status: 'done' },
    { text: 'Weather impact', status: 'done' },
    { text: 'Demographic behavior', status: 'todo' },
    { text: 'Marketing and communications effectiveness', status: 'todo' },
];
