import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { CodeSimple } from 'phosphor-react';
import Select from 'andromeda/selects/select';
import { CONSOLE_MODULE_NAME_MAP, MODULES_ORDER } from '../console/ConsoleModule';
import { useActiveUseCasesData } from '../console/useStasusesData';
import StyledTooltip from './styledTooltip';

export const formatUseCaseName = (useCase: string) =>
    CONSOLE_MODULE_NAME_MAP[useCase] ?? _.startCase(_.lowerCase(useCase));

interface UseCaseDropdownProps {
    selectedModule: string;
    selectedNamespace?: string;
    updateSelectedModule: (value: string) => void;
    modules?: string[];
    clearable?: boolean;
}

export const UseCaseDropdown: React.FC<UseCaseDropdownProps> = ({
    selectedModule,
    updateSelectedModule,
    selectedNamespace,
    modules,
    clearable,
}) => {
    const { data: statusesData, isLoading } = useActiveUseCasesData(selectedNamespace);
    const { statusesOrder, statusesOrderListLength } = {
        statusesOrder: MODULES_ORDER,
        statusesOrderListLength: Object.keys(MODULES_ORDER).length,
    };

    const moduleOptions = useMemo(
        () =>
            (modules || statusesData)
                .sort(
                    (a, b) =>
                        (statusesOrder?.[a] ?? statusesOrderListLength) -
                        (statusesOrder?.[b] ?? statusesOrderListLength),
                )
                .map(module => ({
                    value: module,
                    label: formatUseCaseName(module),
                })),
        [modules, statusesData, statusesOrder, statusesOrderListLength],
    );
    useEffect(() => {
        if (
            isLoading ||
            !moduleOptions ||
            moduleOptions.find(item => item.value === selectedModule) ||
            !moduleOptions?.[0]?.value ||
            clearable
        )
            return;
        updateSelectedModule(moduleOptions?.[0]?.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleOptions]);

    return (
        <div className="flex min-w-[244px] items-center">
            <StyledTooltip
                disabled={moduleOptions.length > 0}
                label="No use_cases found for the Selected namespace"
            >
                <Select
                    data={moduleOptions}
                    value={
                        (moduleOptions.length === 0
                            ? formatUseCaseName(selectedModule)
                            : selectedModule) ?? 'All'
                    }
                    onChange={updateSelectedModule}
                    cleanDesign
                    label={
                        <div className="flex h-10 items-center gap-1 text-base text-blue-600">
                            <CodeSimple size={20} className="mt-0.5" />
                            Module:
                        </div>
                    }
                    width={isLoading ? 244 : 155}
                    searchable={false}
                    loading={isLoading}
                    clearable={clearable}
                    placeholder="select a model"
                    defaultValue="All"
                />
            </StyledTooltip>
        </div>
    );
};
