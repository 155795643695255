import { useEffect, useRef } from 'react';
import cx from 'classnames';
import { Message } from './types';
import { ChatMessage } from './chatMessage';

const ChatMessages = ({
    messages,
    loading,
    mode,
    title,
}: {
    messages;
    loading;
    mode;
    title: string;
}) => {
    const chatContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    if (messages[0].length === 0) return null;

    return (
        <div
            className="w-full overflow-y-scroll rounded-lg pt-1.5"
            style={{
                minWidth: '520px',
                maxHeight: '900px',
            }}
        >
            <div
                className={cx('w-full overflow-y-scroll', {
                    'h-0': messages[0].length === 0,
                    'h-[50vh]': messages[0].length > 0 && mode === 'full-page',
                    'h-[40vh]': mode === 'initialize' && messages[0].length > 0,
                })}
                ref={chatContainerRef}
            >
                {messages[0].length > 0 &&
                    messages[0].map((message: Message) =>
                        message.role === 'User' ? (
                            <ChatMessage key={message.id} message={message} />
                        ) : (
                            <ChatMessage key={message.id} message={message} />
                        ),
                    )}
                {loading && (
                    <ChatMessage
                        message={{
                            id: 0,
                            message_log: [],
                            role: 'AI',
                            text_input: [`${title} is thinking ...`],
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default ChatMessages;
